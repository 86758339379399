import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../../event-bus.js'
import template from './mobile_track_individual_template.slim'
import axios from 'axios'
import './components/score_card_panel'
import './components/medium-panel'
import _ from 'lodash'

Vue.component('mobile-track-individual', {
  mixins: [template],
  props: [
    'school_slug',
    'session',
    'objectives_options',
    'is_iphone',
    'learner',
    'past_assessments',
    'goal_objective_names',
    'past_objective_scores',
    'next_step_data',
    'next_steps',
    'learners_options',
    'current_staff',
    'available_tags',
    'score_band'
  ],
  data() {
    return {
      checklistItems: this.session.objectives,
      initialChecklistItems: [],
      activeAccordionItems: [],
      searchQuery: '',
      selectedItems: this.getInitialSelectedItems(),
      sessionState: this.session,
      sessionEdits: this.initializeSessionEdits(this.session),
      sessionNote: this.session.notes,
      sessionNotification: this.session.notification,
      teacherLearner: this.learner.learner_type == 'Staff',
      objectiveNote: null,
      notesWysiwygToggleKey: 0,
      pastObjectiveScores: this.past_objective_scores,
      selectedObjective: {},
      showSessionNote: false,
      showSessionMedium: false,
      isDraft: this.session.draft,
      isNotified: false,
      modalOpen: false,
      sessionId: this.session.id,
      learnerName: this.session.assessee.full_name,
      observer: this.session.assessor.full_name,
      goal: this.session.goal,
      sessionName: this.session.assessment_name,
      menuHidden: true,
      tagsDropdownMenuHidden: true,
      availableTags: this.available_tags,
      showSessionNotification: false,
      nextStepList: [],
      singleNextStep: [],
      completedNextSteps: [],
      nextStep: {
        title: '',
        description: '',
        dueDate: '',
        nextStepOwner: null,
        objective: [],
      },
      editedNextStep: {
        id: 0,
        title: '',
        description: '',
        dueDate: '',
        nextStepOwner: [],
        objectives: [],
      },
      toBeDeletedNextStep: {
        id: 0
      },
      selectAllOwnersChecked: false,
    }
  },

  computed: {
    filteredChecklistItems() {
      const query = this.searchQuery.toLowerCase();
      return this.checklistItems
        .map(item => ({
          ...item,
          checklist_items_list: item.checklist_items_list.filter(subItem =>
            subItem.name.toLowerCase().includes(query)
          ),
        }))
        .filter(item => item.checklist_items_list.length > 0);
    },
    checkedItemsCount() {
      return this.checklistItems.filter(item => item.checklist_items_list.length > 0).map(item =>
        item.checklist_items_list.filter(subItem => subItem.checked).length
      );
    },
    isActiveAccordionItem() {
      return (index, itemId) => this.activeAccordionItems.includes(index) || itemId === this.selectedObjective.id;
    },
    selectedCheckboxes() {
      this.selectedItems = this.checklistItems.flatMap(item =>
        item.checklist_items_list.filter(subItem => subItem.checked).map(subItem => ({
          label: `${item.title} > ${subItem.name}`,
        }))
      );
      return this.selectedItems;
    },
    hasNotes() {
      return (
        this.sessionState.notes !== '' &&
        this.sessionState.notes !== '<p></p>' &&
        this.sessionState.notes !== null

      )
    },
    hasNotification() {
      return (
        this.sessionState.notification !== '' &&
        this.sessionState.notification !== '<p></p>' &&
        this.sessionState.notification !== null
      )
    },
    hasMedium() {
      return !_.isEmpty(this.sessionState.media[0]?.medium_url)
    },
    noGoal() {
      return this.goal == null
    },
    learnersOptions() {
      return Object.values(this.learners_options);
    },
    nextSteps(){
      return this.nextStepList
    },
  },
  created(){
    this.getNextStepList()
    this.initialChecklistItems = _.cloneDeep(this.checklistItems);
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return `${formattedDate}`;
    },
    formatDueDateForAddEditNextStep(dateString) {
      if ([null, ""].includes(dateString)) {
        return "";
      }
      const dateObj = new Date(dateString.replace(/(\d+)(st|nd|rd|th)/, '$1'));
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getNextStepList(){
      axios.get(`/go/${this.school_slug}/v2/next_steps?session_id=${this.session.id}`,)
        .then(response => {
         this.nextStepList = response.data
        })
        .catch(error => {
          const statusCode = error.response.status;
        })
    },
    selectAllOwners() {
      if (this.selectAllOwnersChecked) {
        this.nextStep.nextStepOwner = this.learnersOptions
      } else {
        this.nextStep.nextStepOwner = null
      }
    },
    changeAllOwnersCheckbox() {
      this.selectAllOwnersChecked = this.nextStep.nextStepOwner.length === this.learnersOptions.length;
    },
    initializeSessionEdits(session) {
      return {
        scheduled_at: session.scheduled_at,
        assessment_name: session.assessment_name,
        objectives: _.filter(this.objectives_options, option =>
          _.includes(session.objective_ids, option.value.toString())
        ),
        errors: {},
      }
    },
    publishSession() {
      window.location.pathname = `/go/${this.school_slug}/individual_sessions/${
        this.session.id
      }/notification/new`
    },
    saveAndExit() {
      window.location.pathname = `go/${this.school_slug}/v2/dashboard`
    },
    togglePublishMenu() {
      this.menuHidden = !this.menuHidden;
    },
    handleClick(event) {
      if($(event.target).hasClass('tags-dropdown-button')) {
        this.tagsDropdownMenuHidden = !this.tagsDropdownMenuHidden;
      } else if($(event.target).closest('.mobile-session-tags .dropdown-menu').length > 0) {
        this.tagsDropdownMenuHidden = false;
      } else {
        this.tagsDropdownMenuHidden = true;
      }
    },
    scoreAllParams() {
      return {
        recipients: 'All Objectives',
        subject: this.sessionState.assessee.full_name,
        scores: this.sessionState.mastery_scores,
        subject_id: this.sessionState.assessee.id,
      }
    },
    toggleNotificationDraft() {
      this.showSessionNotification = !this.showSessionNotification
    },
    toggleSessionNote() {
      this.showSessionNote = !this.showSessionNote
      this.showSessionMedium = false
    },
    toggleHistoricalSessionNotes() {
      this.$refs.historicalSessionNotes.toggleVisible()
    },
    toggleSessionMedium() {
      this.showSessionMedium = !this.showSessionMedium
      this.showSessionNote = false
    },
    toggleScoreAllPanel() {
      this.$refs.scoreAllPanel.toggleVisible()
    },
    openObjectiveDetailsModal(objective) {
      this.selectedObjective = objective
      this.$refs.objectiveDetailsPanel.toggleVisible()
    },
    openCheckListItemsModal(objective) {
      this.selectedObjective = objective
      this.$refs.checkListPanel.toggleVisible()
      document.querySelector('.check-list-footer')?.classList.add('fixed-footer')
    },
    openObjectiveNoteNodal(objective) {
      this.selectedObjective = objective
      this.objectiveNote = this.findOrInitializeObjectiveNote()
      this.notesWysiwygToggleKey += 1
      this.$refs.objectiveNotePanel.toggleVisible()
    },
    findOrInitializeObjectiveNote() {
      let note = ''
      const { mastery_scores } = this.sessionState
      const score = _.find(mastery_scores, {
        mastery_objective_id: this.selectedObjective.id,
      })
      if (score && score.notes) {
        note = score.notes
      }
      return note
    },
    updateObjectiveNote(input) {
      this.objectiveNote = input.target.value
    },
    onPressCancelObjectiveNote() {
      this.$refs.objectiveNotePanel.toggleVisible()
    },
    onPressCancelNextStep() {
      this.$refs.addNextStepPanel.toggleVisible()
    },
    onPressSaveNextStep(){
      const formattedDueDate = this.formatDueDateForAddEditNextStep(this.nextStep.dueDate);
      const objectiveIds = this.nextStep.objective.map(obj => obj.value);
      const ownerIds = this.nextStep.nextStepOwner?.map(obj => obj.value);

      const data = {
        next_step: {
          session_id: this.sessionId,
          creation_location_type: 'IndividualAssessment',
          creation_location_id: this.sessionId,
          title: this.nextStep.title,
          description: this.nextStep.description,
          owner_ids: ownerIds,
          due_date: formattedDueDate,
          objective_ids: objectiveIds
        }
      };
      axios.post(`/go/${this.school_slug}/v2/next_steps`, data,{
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          this.getNextStepList()
          this.onPressCancelNextStep()
          this.clearData()
          this.selectAllOwnersChecked = false;
        })
        .catch(error => {
          const statusCode = error.response.status;
        });
    },
    onPressUpdateNextStep(id) {
      const formattedDueDate = this.formatDueDateForAddEditNextStep(this.editedNextStep.dueDate);
      const objectiveIds = this.editedNextStep.objectives.map(obj => obj.value);

      const data = {
        next_step: {
          title: this.editedNextStep.title,
          description: this.editedNextStep.description,
          due_date: formattedDueDate,
          owner_id: this.editedNextStep.nextStepOwner.value,
          objective_ids: objectiveIds
        }
      };

      axios.patch(`/go/${this.school_slug}/v2/next_steps/${this.editedNextStep.id}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          this.getNextStepList();
          this.$refs.singleNextStepEditPanel.toggleVisible();
          this.clearData();
        })
        .catch(error => {
          const statusCode = error.response.status;
        });
    },
    onPressDeleteNextStep(id) {
      this.toBeDeletedNextStep.id = id;
      this.$refs.singleNextStepEditPanel.toggleVisible();
      this.$refs.modalBackdrop.toggleVisible();
      this.$refs.deleteNextStep.toggleVisible();
    },
    onPressCancelDeleteNextStep() {
      this.clearData();
      this.$refs.deleteNextStep.toggleVisible();
      this.$refs.modalBackdrop.toggleVisible();
    },
    onPressConfirmDeleteNextStep() {
      axios
        .delete(`/go/${this.school_slug}/v2/next_steps/${this.toBeDeletedNextStep.id}`)
          .then(response => {
            this.getNextStepList();
            this.$refs.deleteNextStep.toggleVisible();
            this.clearData();
            this.$refs.modalBackdrop.toggleVisible();
          })
          .catch(error => {
            const statusCode = error.response.status;
          });
    },
    clearData(){
      this.nextStep = {
        title:'',
        description:'',
        dueDate:'',
        nextStepOwner:null,
        objective:[],
      }
      this.editedNextStep = {
        id: 0,
        title: '',
        description: '',
        dueDate: '',
        nextStepOwner: [],
        objectives: []
      }
      this.toBeDeletedNextStep = {
        id: 0
      }
    },
    onPressSaveObjectiveNote() {
      const note = {
        mastery_objective_id: this.selectedObjective.id,
        notes: this.objectiveNote,
      }
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/make_score_note`,
          {
            note,
          }
        )
        .then(response => {
          const { score, assessee_recent_scores } = response.data
          this.sessionState.mastery_scores = this.updatedMasteryScores([score])
          this.sessionState.assessee_recent_scores = assessee_recent_scores
          this.$refs.objectiveNotePanel.toggleVisible()
        })
        .catch(error => {
          console.error(error)

          const statusCode = error.response.status;
          if (statusCode == 401 || statusCode == 404) { document.location.reload(); }
        })
    },
    getScore(objective) {
      return _.find(this.sessionState.mastery_scores, {
        mastery_objective_id: objective.id,
      })
    },
    getScoreParams(objective, score) {
      return {
        mastery_score: {
          mastery_objective_id: objective.id,
          score,
        },
      }
    },
    updateScores(scores) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/score`,
          {
            scores,
          }
        )
        .then(response => {
          const { mastery_scores, assessee_recent_scores } = response.data
          this.sessionState.mastery_scores = this.updatedMasteryScores(
            mastery_scores
          )
          this.sessionState.assessee_recent_scores = assessee_recent_scores
        })
        .catch(error => {
          console.error(error)

          const statusCode = error.response.status;
          if (statusCode == 401 || statusCode == 404) { document.location.reload(); }
        })
    },
    onPressSaveAllScores(score) {
      const scores = _.map(this.sessionState.objectives, objective =>
        this.getScoreParams(objective, score)
      )
      this.updateScores(scores)
      this.$refs.scoreAllPanel.toggleVisible()
    },
    updateScore(objective, score) {
      const scores = [this.getScoreParams(objective, score)]
      this.updateScores(scores)
    },
    updatedMasteryScores(newMasteryScores) {
      return _.unionWith(
        newMasteryScores,
        this.sessionState.mastery_scores,
        (a, b) => {
          return (
            a.student_id === b.student_id &&
            a.mastery_objective_id === b.mastery_objective_id
          )
        }
      )
    },
    onPressCancelSessionNote() {
      this.sessionNote = this.sessionState.notes
      this.toggleSessionNote()
    },
    saveSessionNoteNotification(onSuccess=_.noop) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/update_notes`,
          {
            notes: this.sessionNote,
            notification: this.sessionNotification
          }
        )
        .then(response => {
          this.sessionState.notes = this.sessionNote
          this.sessionState.notification = this.sessionNotification
          onSuccess()
        })
        .catch(error => {
          console.error(error)

          const statusCode = error.response.status;
          if (statusCode == 401 || statusCode == 404) { document.location.reload(); }
        })
    },
    saveSessionNote() {
      this.saveSessionNoteNotification(this.toggleSessionNote)
    },
    saveSessionNotification() {
      this.saveSessionNoteNotification(this.toggleNotificationDraft)
    },
    cancelNotification() {
      this.sessionNotification = this.sessionState.notification
      this.toggleNotificationDraft()
    },
    openSessionEditPanel() {
      this.$refs.sessionEditPanel.toggleVisible()
    },
    openNextStepAddPanel() {
      $('#next-step-due-date').find('input').val('');
      this.$refs.addNextStepPanel.toggleVisible();
    },
    toggleElementsUponNextStepCompletion(isCompleted) {
      let el = $('#show-single-next-step');
      if (isCompleted) {
        el.find('.edit-pencil-icon').hide();
        el.find('.completion-data').show();
      }
      else {
        this.singleNextStep.completed = false;
        el.find('.edit-pencil-icon').show();
        el.find('.completion-data').hide();
      }
    },
    showSingleNextStep(id) {
      this.fetchSingleNextStep(id);
      this.toggleElementsUponNextStepCompletion(this.singleNextStep.completed);
      this.$refs.showSingleNextStep.toggleVisible()
    },
    showCompletedNextStepHistory(render_location_id, render_location) {
      this.fetchCompletedNextStepHistory(render_location_id, render_location);
      this.$refs.completedNextStepPanel.toggleVisible()
    },
    fetchCompletedNextStepHistory(render_location_id, render_location){
      axios.get(`/go/${this.school_slug}/v2/next_steps/completed_next_steps_history?render_location_id=${render_location_id}&render_location=${render_location}`)
          .then(response => {
            this.completedNextSteps = response.data
          })
          .catch(error => {
            const statusCode = error.response.status;
          })
    },
    fetchSingleNextStep(id){
      axios.get(`/go/${this.school_slug}/v2/next_steps/${id}`)
        .then(response => {
          this.singleNextStep = response.data
          this.singleNextStep.created_at = this.formatDate(this.singleNextStep.created_at)
          this.singleNextStep.assginedByName = this.singleNextStep.assigned_by.name
          this.singleNextStep.ownerName = this.singleNextStep.owner.name
          this.singleNextStep.objectiveName = this.singleNextStep.objective_names.join(', ');
          this.singleNextStep.completed = response.data.completed;
        })
        .catch(error => {
          const statusCode = error.response.status;
        })
    },
    openSingleNextStepEditPanel(id) {
      this.initializeSingleNextStepEdit(id)
      this.$refs.showSingleNextStep.toggleVisible()
      this.$refs.singleNextStepEditPanel.toggleVisible()
    },
    initializeSingleNextStepEdit(id) {
      axios.get(`/go/${this.school_slug}/v2/next_steps/${id}/edit`)
        .then(response => {
          let nextStepData = response.data;
          this.editedNextStep.id = nextStepData.id;
          this.editedNextStep.title = nextStepData.title;
          this.editedNextStep.description = nextStepData.description;
          this.editedNextStep.dueDate = nextStepData.due_date;
          this.$refs.singleNextStepEditPanelDueDate.value = nextStepData.due_date;
          this.editedNextStep.nextStepOwner = _.filter(this.learners_options, option => _.includes([nextStepData.owner_id.toString()], option.value.toString()));
          this.editedNextStep.objectives = _.filter(this.objectives_options, option => _.includes(nextStepData.objective_ids, option.value.toString()));
        })
        .catch(error => {
          const statusCode = error.response.status;
        });
    },
    toggleNextStep(id,type){
      $('.next-step-list-item').css('opacity', '0.7');
      $('.next-step-mark').attr('disabled', 'disabled');

      axios.post(`/go/${this.school_slug}/v2/next_steps/${id}/completion_toggle`,{
        headers: {
          'Content-Type': 'application/json'
        },
        completion_location_type: 'IndividualAssessment',
        completion_location_id: this.session.id,
        completed_by_id: this.current_staff.id
      })
      .then(response => {
        this.fetchSingleNextStep(id);
        const index = this.nextStepList.findIndex(obj => obj.id === id);
        this.nextStepList[index] = { ...this.nextStepList[index], completed: response.data.completed };
        this.toggleElementsUponNextStepCompletion(response.data.completed);
        this.getNextStepList();

        $('.next-step-list-item').css('opacity', '1');
        $('.next-step-mark').removeAttr('disabled');
      })
      .catch(error => {
        const statusCode = error.response.status;
      })
    },
    onPressCancelEditSession() {
      this.sessionEdits = this.initializeSessionEdits(this.sessionState)
      this.$refs.sessionEditPanel.toggleVisible()
    },
    onPressSaveSession() {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${this.session.id}`,
          {
            individual_session: this.individualSessionParams(),
          }
        )
        .then(response => {
          const updatedSession = response.data
          this.sessionState = updatedSession
          this.sessionEdits = this.initializeSessionEdits(updatedSession)
          this.selectedObjective = {}
          this.$refs.sessionEditPanel.toggleVisible()
          this.checklistItems = updatedSession.objectives
          this.initialChecklistItems = _.cloneDeep(this.sessionState.objectives)
        })
        .catch(error => {
          this.sessionEdits.errors = error.response.data
        })
    },
    onPressDeleteSession() {
      if (confirm('Are you sure you want to delete this session?')) {
        axios
          .delete(
            `/go/${this.school_slug}/v2/individual_sessions/${this.session.id}`
          )
          .then(response => {
            window.location = response.data;
          })
          .catch(err => {
            this.sessionEdits.errors = `Unable to delete session due to: ${err}`;
          })
      }
    },
    individualSessionParams() {
      const { assessment_name, scheduled_at } = this.sessionEdits
      return {
        assessment_name,
        scheduled_at,
        objective_ids: _.map(this.sessionEdits.objectives, o => o.value),
      }
    },
    saveMedium(medium_data, mediumId, successCallback, failureCallback) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/update_medium`,
          { medium_data, medium_id: mediumId }
        )
        .then(response => {
          const media = response.data
          this.sessionState.media = media
          const new_medium = media.slice(-2)[0]
          successCallback(
            new_medium.medium_url,
            new_medium.mime_type,
            new_medium.id
          )
        })
        .catch(error => {
          failureCallback("Couldn't Save Media")
        })
    },
    deleteMedium(mediumId, onSaveMediumFailed) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/delete_medium`,
          { medium_id: mediumId }
        )
        .then(response => {
          const remaining_media = _.reject(this.sessionState.media, [
            'id',
            mediumId,
          ])
          this.sessionState.media = remaining_media
        })
        .catch(error => {
          onSaveMediumFailed("Couldn't Delete Media")
        })
    },
    openLearnerDetailsModal(objective) {
      this.selectedObjective = objective
      this.$refs.learnerDetailsPanel.toggleVisible()
    },
    recentScoresForSelectedObjective() {
      const { assessee_recent_scores } = this.sessionState
      return _.get(_.find(assessee_recent_scores, {
        mastery_objective_id: this.selectedObjective.id?.toString()
      }), 'recent_scores')
    },
    recentNotesForSelectedObjective() {
      const recent_scores = this.recentScoresForSelectedObjective()

      return _.reject(recent_scores, score => _.isEmpty(score.notes)).map(
        score => ({
          note: score.notes,
          session_name: score.source,
          formatted_date: score.formatted_date,
          staff: { full_name: score.staff_name },
        })
      )
    },
    setSessionTag(tagId) {
      axios
        .get(
          `/go/${this.school_slug}/tags/${tagId}/set_tag_for_individual_assessment/${this.session.id}`,
        )
        .then(response => {
          if (response.data.success) {
            this.sessionState.attached_tags = response.data.attached_tags;
          }
        });
    },
    toggleAccordionItem(index, selectedId,itemId) {
      if (this.selectedObjective && this.selectedObjective.id === selectedId && this.selectedObjective.id === itemId) {
        this.selectedObjective = {};
        this.activeAccordionItems.push(index);
      }

      const isOpen = this.activeAccordionItems.includes(index);
    
      if (isOpen) {
        this.activeAccordionItems = this.activeAccordionItems.filter(item => item !== index);
      } else {
        this.activeAccordionItems.push(index);
      }
    },
    getInitialSelectedItems() {
      return this.session.objectives.flatMap(item =>
        item.checklist_items_list.filter(subItem => subItem.checked)
      );
    },
    revertChecklistItemsToInitialState() {
      this.checklistItems.forEach(item => {
        const initialItem = _.find(this.initialChecklistItems, { id: item.id });
        item.checklist_items_list.forEach(subItem => {
          subItem.checked = _.find(initialItem.checklist_items_list, { name: subItem.name }).checked;
        });
      });
    },
    onPressSaveChecklist() {
      this.selectedItems = this.checklistItems.flatMap(item =>
        item.checklist_items_list.filter(subItem => subItem.checked)
      );
  
      this.$refs.checkListPanel.toggleVisible()
      this.activeAccordionItems = []
      this.removeFixedFooter()

      const formData = new FormData()
      formData.append('_method', 'patch')
      formData.append('individual_assessment[checklist_item_ids][]', "")
      this.selectedItems.forEach(item => {
        formData.append('individual_assessment[checklist_item_ids][]', item.id)
      });
      formData.append('commit', 'Save Checklist')
  
      axios.post(`/go/${this.school_slug}/individual_sessions/${this.sessionState.id}/update_checklist`, formData, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => {
        this.initialChecklistItems = _.cloneDeep(this.sessionState.objectives)
        this.searchQuery = ''
      })
      .catch(error => {
        const statusCode = error.response.status
      });
    },
    onPressCancelChecklist() {
      this.revertChecklistItemsToInitialState()
      this.$refs.checkListPanel.toggleVisible()
      this.activeAccordionItems = []
      this.removeFixedFooter()
      this.searchQuery = ''
    },
    removeFixedFooter() {
      const footerElement = document.querySelector('.check-list-footer');
      if (footerElement) {
        setTimeout(() => {
          footerElement.classList.remove('fixed-footer');
        }, 300);
      }
    },
  },
  mounted() {
    document.dispatchEvent(new Event('vue:load'))
    EventBus.$on('modal-opening', () => {
      this.modalOpen = true
    })
    EventBus.$on('modal-closing', () => {
      this.modalOpen = false
    })
    $("#delete-next-step>.x-close-icon-alt").on('click', () => {
      this.$refs.modalBackdrop.toggleVisible();
    });
    $("#add-next-step-form label, #single-next-step-edit-form label").on('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    $(document).ready(function() {
      if($("#historical-session-notes-panel").length > 0){
        $('head').append('<style>' +
            'html,body {' +
            '     overflow: auto !important;' +
            '     height: 100%;' +
            '}' +
            '</style>');
      }
    });
  },
})
