import Vue from 'vue/dist/vue.esm'
import template from './users_by_school_with_active_goal_report_template'
import { getUrlWithSlug, getWidth, seriesDataBar } from '../commonChartFunctions';

Vue.component('users_by_school_with_active_goal_report', {
  mixins: [template],
  props: ['staff_data', 'year_info', 'report_date'],
  data() {
    return {
      startDate: '',
      endDate: '',
      series: [{
        name: 'Percentage',
        data: seriesDataBar(this.staff_data)
      }],
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        chart: {
          type: 'bar',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const schoolName = config.w.config.series[0].data[config.dataPointIndex].x;
              const urlWithSlug = getUrlWithSlug(this.year_info.current_schools_year, schoolName);
              const redirectUrl = urlWithSlug + "/active_goals_report?by_learner=true";
              window.open(redirectUrl, '_blank');
            }
          }
        },
        yaxis: {
          title: {
            text: "% of Users",
            style: {
              color: '#949494'
            }
          },
          forceNiceScale: true,
          labels: {
            formatter: function (value, index) {
              if(Math.round(value) == value){
                return value.toFixed(0)+ '%';
              } else {
                return value.toFixed(2)+ '%';
              }
            },
          }
        },
        xaxis: {
          labels: {
            trim: true,
            rotate: -55,
          }
        },
        grid: {
          row: {
            colors: ['#FFFFFF', '#F3F3F3']
          },
          padding: {
            left: 50,
            bottom: 30
          },
        }
      }
    }
  },
  computed: {
    chartWidth: function () {
      return getWidth(this.series[0].data.length);
    }
  }
});
