import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../../../../event-bus.js'
import axios from 'axios'
import template from './new_session_template_form_template.slim'
import SessionTemplatesService, {
  sessionTemplateFormable,
} from '../../../../services/session_templates_service.js'

Vue.component('new-session-template-form', {
  mixins: [template, sessionTemplateFormable],
  props: [
    'school_slug',
    'current_staff',
    'kind_options',
    'classroom_options',
    'assessors_options',
    'objectives_options',
    'assessment_template_options',
    'current_staff_name',
    'add_session_template',
  ],
  data() {
    return {
      showNotes: false,
      learnersOptions: [],
      classroomLearners: [],
      defaultAssessor: this.current_staff,
      addWholeClassDisabled: true,
      sessionTemplateForm: this.initializeSessionTemplateForm(
        this.current_staff
      ),
      visible: false,
    }
  },
  computed: {
    originalTemplate() {
      return this.initializeSessionTemplateForm(this.defaultAssessor)
    },
  },
  methods: {
    initializeSessionTemplateForm(defaultAssessor) {
      return {
        kind: 'Observation',
        title: '',
        assessment_template: null,
        assessors: [defaultAssessor],
        classroom: null,
        learners: [],
        objectives: null,
        notes: null,
        errors: {},
      }
    },
    changeKind(newKind) {
      this.sessionTemplateForm.kind = newKind
    },
    fetchLearners(input) {
      SessionTemplatesService.fetchLearners(input, this)
      this.sessionTemplateForm.learners = []
    },
    closeNewSessionTemplate() {
      this.$refs.newSessionTemplateModal.close()
    },
    onClose() {
      this.resetForm()
    },
    resetForm() {
      this.sessionTemplateForm = this.initializeSessionTemplateForm(
        this.defaultAssessor
      )
      this.learnersOptions = []
    },
    saveNewSessionTemplate() {
      this.toggleSubmitting()
      axios
        .post(`/go/${this.school_slug}/v2/session_templates`, {
          session_template: this.template_params(),
        })
        .then(response => {
          this.add_session_template(response.data)
          this.closeNewSessionTemplate()
        })
        .catch(error => {
          this.sessionTemplateForm.errors = error.response.data
        })
        .finally(this.toggleSubmitting)
    },
    template_params() {
      return SessionTemplatesService.templateParams(this.sessionTemplateForm)
    },
    toggleNotes() {
      this.showNotes = !this.showNotes
    },
  },
  mounted() {
    EventBus.$on('modal-opening', () => {
      this.visible = true
    })
    EventBus.$on('modal-closing', () => {
      this.visible = false
      this.resetForm
    })
    this.sessionTemplateForm.assessors = [this.defaultAssessor]
  },
})
