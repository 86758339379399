import Vue from 'vue/dist/vue.esm'

Vue.component('editable', {
  props: {
    heading: {
      required: false
    },
    defaultOpen: {
      type: Boolean,
      default: false
    }
  },
  template: `
  <div class="editable">
    <div class="links">
      <slot name="heading">
        <h4>{{heading}}</h4>
      </slot>
      <button class="view-button" @click="toggle()">{{ active ? "Cancel" : "Edit Goal" }}</button>
    </div>
    <div class="editable-content">
      <slot :name="active ? 'edit' : 'content'"></slot>
    </div>
  </div>
  `,
  data() {
    return {
      active: this.defaultOpen,
      dirty: false
    }
  },
  methods: {
    toggle() {
      if (this.active && this.dirty) {
        if (!window.confirm('Your changes will not be saved. Continue?')) {
          return;
        } else {
          this.dirty = false;
        }
      }
      this.active = !this.active;
    },
  },
  updated() {
    if (this.active) {
      tinymce.remove('textarea.tinymce');
      tinymce.init({
        selector: 'textarea.tinymce',
        menubar: false,
        elementpath: false,
        branding: false,
        browser_spellcheck: true,
        default_link_target:"_blank",
        contextmenu: false,
        plugins: 'emoticons table insertdatetime link advlist lists',
        toolbar: 'insert | undo redo |  formatselect | bold italic forecolor backcolor' +
                  '  | alignleft aligncenter alignright alignjustify | ' +
                  ' bullist numlist outdent indent table | removeformat | insertdatetime | emoticons | link | help',
        table_default_styles: {
          width: '100%', 'table-layout': 'fixed',
        },
        paste_data_images: true,
        content_css: '/assets/tinymce_custom.css',
        setup: (editor) => {
          editor.on("change", () => {
            if (editor.isDirty()) {
              this.dirty = true;
            }
          });
          editor.addShortcut('meta+shift+l', 'Left align', () => {
            editor.execCommand('JustifyLeft');
          });
          editor.addShortcut('meta+shift+e', 'Center align', () => {
            editor.execCommand('JustifyCenter');
          });
          editor.addShortcut('meta+shift+r', 'Right align', () => {
            editor.execCommand('JustifyRight');
          });
          editor.addShortcut('meta+shift+7', 'Numbered list', () => {
            editor.execCommand('InsertOrderedList', false, {
              'list-style-type': 'decimal',
              'list-item-attributes': {class: 'mylistitemclass'},
              'list-attributes': {id: 'mylist'}
            });
          });
          editor.addShortcut('meta+shift+8', 'Bulleted list', () => {
            editor.execCommand('InsertUnorderedList', false, {
              'list-style-type': 'disc',
              'list-item-attributes': {class: 'mylistitemclass'},
              'list-attributes': {id: 'mylist'}
            });
          });
        }
      });
      $(this.$el).find('.selectize:not(.selectized)').selectize();
    } else {
      document.dispatchEvent(new Event('vue:load'));
    }
  },
  mounted() {
    $(this.$el).on("change", () => {
      this.dirty = true;
    });
    document.dispatchEvent(new Event('vue:load'));
  }
})
