import Vue from 'vue/dist/vue.esm'
import template from './active_goals_report_template.slim'
import { VueGoodTable } from 'vue-good-table'

Vue.component('active-goals-report', {
  mixins: [template],
  components: {
    VueGoodTable,
  },
  props: ['mastery_objective_rows', 'show_objectives_table', 'learner_active_goal_rows'],
  data() {
    return {
      showObjectivesTable: true,
      columnsForObjectives: [
        {
          label: 'Mastery Component / Mastery Objective',
          field: 'masteryObjective',
          tdClass: 'mastery-objective-td',
          sortable: true,
          sortFn: this.sortFn,
          html: true,
        },
        {
          label: 'Teachers w/ Objective + Current Active Goal',
          field: 'count',
          type: 'number',
          tdClass: 'mastery-objective-count',
          thClass: 'mastery-objective-count',
        },
      ],
      columnsForLearners: [
        {
          label: 'Name',
          field: 'name',
          tdClass: 'name-td',
        },
        {
          label: 'Objectives Tied to Active Goal',
          field: 'objectives',
          tdClass: 'objectives-td',
          width: '400px'
        },
        {
          label: 'Active Goal Text',
          field: 'activeGoal',
          tdClass: 'active-goal-td',
          html: true
        },
      ],
    }
  },
  mounted() {
    if(this.show_objectives_table === false){
      document.getElementById('learnersRadio').click();
    }
  },
  methods: {
    sortFn(x, y) {
      x = x.replace(/<\/?a[^>]*>/g, '')
      y = y.replace(/<\/?a[^>]*>/g, '')

      return x < y ? -1 : x > y ? 1 : 0
    },
    toggleObjectivesTable() {
      this.$children[0].sorts = []
      this.showObjectivesTable = true
    },
    toggleLearnersTable() {
      this.$children[0].sorts = []
      this.showObjectivesTable = false
    },
  }
})
