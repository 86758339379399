import Vue from 'vue/dist/vue.esm'
import template from './launch_session_template.slim'
import axios from 'axios'
import moment from 'moment'
import './components/name_date'
import './components/classroom_students'
import './components/launch_header'
import './components/launch_footer'
import './components/session_objectives'
import './components/session_preview'
import { EventBus } from '../../event-bus.js'
import _ from 'lodash'

Vue.component('launch-session', {
  mixins: [template],
  props: ['template', 'session_options', 'clone_options', 'current_learner_id'],
  data() {
    return {
      form: {
        name: this.setName(),
        date_time: this.getDate(),
        narrative_feedback: this.narrativeFeedback(),
        assessors: this.setAssessors(),
        classroom: this.setClassroom(this.template.classroom_id),
        learners: this.setInitialLearners(),
        objectives: this.setObjectives(),
        notes: this.setNotes(),
        scheduled_at: moment(),
        selfReflection: this.selfReflection(),
      },
      initialized: false,
      learnerOptions: [],
      sessionOptions: this.session_options,
      step: 1,
      previewOpen: false,
      inEditMode: false,
      schoolSlug: document.getElementById('app').dataset.schoolSlug,
      usesGroupObservations: this.$usesGroupObservations(),
      errors: null,
    }
  },
  methods: {
    setNotes() {
      const { clone_options, template } = this
      if (clone_options) return clone_options.notes
      if (template.notes) return template.notes
      return ''
    },
    setName() {
      const { clone_options, template } = this
      return (
        template.title || (clone_options && clone_options.session_name) || ''
      )
    },
    setInitialLearners() {
      const { clone_options } = this
      if (clone_options) return clone_options.learners.map(l => l.label)
      return []
    },
    setAssessors() {
      const { clone_options } = this
      if (clone_options) return clone_options.assessors.map(a => a.value)
      return [this.session_options.current_staff.value].slice()
    },
    setObjectives() {
      const { clone_options, template } = this
      if (clone_options) return clone_options.objectives.map(o => o.value)
      if (template.objective_ids) return template.objective_ids.slice()
      return []
    },
    narrativeFeedback() {
      return this.template.narrative_feedback
    },
    selfReflection() {
      return this.template.self_reflection
    },
    setLearners() {
      const { learnerOptions, clone_options } = this
      if (clone_options) {
        this.form = {
          ...this.form,
          learners: clone_options.learners.map(l => l.label),
        }
      } else if (this.template.self_reflection) {
        this.form = {
          ...this.form,
          learners: [
            _.find(learnerOptions, { value: this.current_learner_id }).label,
          ],
        }
      } else {
        this.form = {
          ...this.form,
          learners: _.compact(
            this.template.learner_ids.map(id => {
              try {
                return _.find(learnerOptions, { value: id }).label
              } catch (e) {
                console.warn('Did not find learner for id', id)
              }
            })
          ),
        }
      }
      this.initialized = true
    },
    determineStep() {
      if (this.form.name && this.form.assessors) {
        this.step = 2

        if (this.form.objectives.length > 0 && !this.form.selfReflection) {
          this.step = 3

          if (this.form.learners.length > 0 && this.form.classroom) {
            this.previewOpen = true
          }
        } else if (
          this.form.objectives.length > 0 &&
          this.form.selfReflection
        ) {
          this.previewOpen = true
        }
      }
    },
    setClassroom(id) {
      const { clone_options, session_options } = this
      if (clone_options) return clone_options.classroom

      return session_options.classroom_options.find(c => c.value === id)
    },
    editAndLaunchMode() {
      this.step = 1
      this.inEditMode = true
    },
    getLearners() {
      this.form.learners = []
      if (this.form.classroom) {
        axios
          .get(
            `/go/${
              this.$schoolSlug
            }/v2/session_templates/classroom_learners?classroom_id=${
              this.form.classroom.value
            }`
          )
          .then(response => {
            this.learnerOptions = response.data
            if (!this.initialized) {
              this.setLearners()
              this.determineStep()
            }
          })
          .catch(err => console.log(err))
      } else {
        this.determineStep()
      }
    },
    getDate() {
      let date = new Date()
      return date.toISOString().split('T')[0]
    },
    sendSession(sessionType) {
      axios
        .post(`/go/${this.$schoolSlug}/v2/${sessionType}s`, {
          [sessionType]: this.sessionParams(sessionType),
        })
        .then(response => (window.location = response.data.path))
        .catch(error => (this.errors = error.response.data))
    },
    launchNewSession() {
      const singleLearner = this.form.learners.length === 1
      this.sendSession(
        singleLearner ? 'individual_session' : 'observation_session'
      )
    },
    sessionParams() {
      const session = this.form
      const student_ids = session.learners
        ? session.learners.map(
            l => this.learnerOptions.find(({ label }) => label === l).value
          )
        : null
      const sharedParams = {
        classroom_id: this.form.classroom.value,
        staff_ids: this.form.assessors ? this.form.assessors : null,
        student_ids,
      }
      return {
        ...sharedParams,
        session_name: session.name,
        narrative_feedback: session.narrative_feedback,
        scheduled_at: session.scheduled_at,
        session_template_id: this.template.id,
        assessment_template_id: this.template.assessment_template_id,
        objective_ids: session.objectives ? session.objectives : null,
        assessee_id: _.first(student_ids),
        notes: session.notes,
      }
    },
  },
  mounted() {
    setTimeout(this.getLearners, 1)
    EventBus.$on('fetch-learners', () => this.getLearners())
    EventBus.$on('next-step', () => (this.step += 1))
    EventBus.$on('previous-step', () => (this.step -= 1))

    EventBus.$on('select-learner', learner => {
      if (!this.usesGroupObservations) {
        this.form.learners = [learner.label]
      } else if (!this.form.learners.includes(learner.label)) {
        this.form.learners.push(learner.label)
      } else {
        this.form.learners.splice(this.form.learners.indexOf(learner.label), 1)
      }
    })

    EventBus.$on('select-objective', id => {
      let newObjectives = this.form.objectives.slice()

      if (newObjectives.includes(id)) {
        newObjectives = newObjectives.filter(obj => obj !== id)
      } else {
        newObjectives.push(id)
      }

      this.form.objectives = newObjectives
    })

    EventBus.$on('select-assessor', id => {
      let newAssessors = this.form.assessors.slice()

      if (newAssessors.includes(id)) {
        newAssessors = newAssessors.filter(obj => obj !== id)
      } else {
        newAssessors.push(id)
      }

      this.form.assessors = newAssessors
    })

    EventBus.$on('toggle-preview', () => {
      this.editAndLaunchMode()
      this.previewOpen = !this.previewOpen
    })

    EventBus.$on('set-edit-launch-mode', () => {
      this.editAndLaunchMode()
      this.previewOpen = false
    })

    EventBus.$on('launch-session', () => {
      this.launchNewSession()
    })
  },
})
